.main {
  height: 100%;
  width: 100%;
}

.renderview {
  position: absolute;
  width: 100%;
  height: 100%;
}

.bahn {
  top: 20%;
  left: 12%;
}

.webww {
  z-index: 100 !important;
  top: 20%;
  left: 10%;
  width: 30%;
}

.terminal {
  top: 0%;
  left: 29%;
}

.sticky {
  z-index: 500;
  position: absolute;
  bottom: 50px;
  right: 0px;
}

.help {
  width: 40px;
  height: 40px;
}

.blendin
{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color:#ff802f;
  z-index: 1000;

  transition: opacity 0.5s;
  opacity: 1;
}

.blendout
{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color:#ff802f;
  z-index: 1000;

  transition: opacity 0.5s;
  opacity: 0;

}



.web {
  position: absolute;
  z-index: 100 !important;
  top: 60%;
  right: 5px;
  width: 20%;
  transition: all 0.3s 0.3s ease;
  transform: translateX(21px);
}

.webhidden {
  position: absolute;
  z-index: 100 !important;
  top: 60%;
  right: 5px;
  width: 20%;
  transition: all 0.3s 0s ease;
  transform: translateX(520px);
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}
