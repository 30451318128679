/* https://www.w3schools.com/css/tryit.asp?filename=trycss3_flexbox_flex */

.main {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(251,251,255);
  background: radial-gradient(circle, rgba(251,251,255,1) 0%, rgba(226,226,235,1) 27%, rgba(255,255,255,1) 100%); 
  overflow: hidden;
}

