html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Monaco, Consolas, "Courier New", monospace;
}
