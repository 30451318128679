/* https://www.w3schools.com/css/tryit.asp?filename=trycss3_flexbox_flex */

.main {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  pointer-events: none;

}
