.main {
  height: 100%;
  width: 400px;
  padding: 20px;

}

.button
{
  padding: 3px;
  width: 100%;
  height: 6em;
}

.buttonText
{
  width: 90%;
  line-height: 1.5em;
}

