/* https://www.w3schools.com/css/tryit.asp?filename=trycss3_flexbox_flex */

.main {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.iframe {
  width: 100%;
  height: 100%;
  z-index: -100;
}

.segment {
  position: absolute;
  z-index: 100 !important;
  top: 65%;
  right: 0%;
  width: 20%;
  height: 60%;
  transition: all 0.3s 0.3s ease;
  transform: translateX(21px);
}

.segmentBorder {
  border-radius: 3px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
}

.segmenthidden {
  position: absolute;
  z-index: 100 !important;
  top: 65%;
  right: 0%;
  width: 30%;
  height: 60%;
  transition: all 0.3s 0s ease;
  transform: translateX(720px);
}

.content {
  width: 100%;
  height: 100%;
  background-color: grey;
}

.contentTransparent {
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  left: -45px;
  top: 0px;
}
