.main {
  position: absolute;
  height: 100%;
  width: 100%;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    173deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(254, 140, 0, 0.3225490879945728) 100%,
    rgba(255, 255, 255, 1) 100%
  );
}

.footer {
  position: absolute;
  bottom: 33px;
  right: 33px;
}
